/**
 * Alpine data object to query and paginate polls.
 */
export const paginatePolls = () => ({
    items: [],
    currentPage: 0,
    canLoadMore: true,
    isLoading: false,
    perPage: 32,
    error: null,
    get url() {
        return `/api/polls?per_page=${this.perPage}&sort_order=${this.$wire.sortOrder}&account_type=${this.$wire.accountType}&is_complete=${this.$wire.showCompleted}`
    },
    async fetchPage(page = 1) {
        const res = await fetch(`${this.url}&page=${page}`, {
            headers: {
                'Accept': 'application/json',
            }
        })
        const json = await res.json()
        return json
    },
    loadNextPage() {
        if (!this.canLoadMore) return
        if (this.isLoading) return
        this.isLoading = true

        this.currentPage++
        
        this.fetchPage(this.currentPage).then(res => {
            if (!res.links.next) this.canLoadMore = false

            res.data.forEach(poll => this.items.push(poll));

            this.isLoading = false
        }).catch(err => {
            console.error(err)
            this.isLoading = false
            this.error = err
        })
    },
    reset() {
        this.items = []
        this.currentPage = 0
        this.canLoadMore = true
        this.isLoading = false
    }
})