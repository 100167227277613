import QRCode from "qrcode";
import * as Sentry from "@sentry/browser";

const POLL_THEMES = [
    { bg: 'bg-yellow-200' },
    { bg: 'bg-pink-200' },
    { bg: 'bg-blue-200' },
    { bg: 'bg-rust-200' },
  ];

export const currentPollStore = () => ({
    randomizedIndex: 0,
    showShareScreen: false,
    hasQrCode: false,
  
    init() {
      this.generateRandomizedIndex();
    },
  
    get randomizedTheme() {
      return POLL_THEMES[this.randomizedIndex];
    },
  
    generateRandomizedIndex() {
      this.randomizedIndex = Math.floor(Math.random() * POLL_THEMES.length);
    },
  
    async copyToClipboard(url) {
      if ('clipboard' in navigator) {
        try {
          await navigator.clipboard.writeText(url);
        } catch (e) {
          Sentry.captureException(e);
        }
      }
    },
  
    async openShareMenu(title, url) {
      if ('share' in navigator) {
        try {
          await navigator.share({
            title,
            text: title,
            url,
          });
        } catch (e) {
          // User aborted share. Do nothing.
        }
      }
    },
  
    async generateQRCode(url) {
      const ref = this.$refs.qr;
  
      if (!ref) {
        return;
      }
  
      try {
        await QRCode.toCanvas(ref, url);
        this.hasQrCode = true;
      } catch (e) {
        Sentry.captureException(e);
      }
    }
  })