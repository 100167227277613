import * as Sentry from "@sentry/browser";
import.meta.glob([
  '../images/**',
  '../fonts/**',
]);
import { intersect } from '@alpinejs/intersect';
import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';
import { paginatePolls } from './data/paginatePolls';
import { currentPollStore } from './data/currentPollStore';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
});

Alpine.plugin(intersect);

Alpine.data('paginatePolls', paginatePolls);
Alpine.data('currentPollStore', currentPollStore);

Livewire.start();